import React, { useGlobal, useState } from "reactn";
import { Row, CA } from "../../../styledComponents/grid";
import {
  FaBan,
  FaEdit,
  FaLock,
  FaDollarSign,
  FaUserMd,
  FaFlag,
  FaClipboard,
  FaArrowAltCircleUp,
  FaStripeS,
  FaCreditCard,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { USER_IMPERSONATION } from "../users/userGraphQL";
import config from "../../../api/config";
import {
  CREATE_CLINIC_SETTING,
  useClinicsQuery,
  Cancel_Subscription,
  CREATE_FEES,
  UPDATE_STRIPE_ID,
} from "./clinicGraphQL";
import { Button } from "../../../styledComponents/form";
import { useMutation } from "@apollo/react-hooks";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import Form from "../../app/form";
import formatRelative from "date-fns/formatRelative";
import Loading from "../../app/loading";
import Popup from "../../app/popup";

function ImpersonationLink({ data }) {
  const [impersonate] = useMutation(USER_IMPERSONATION);
  async function quickImpersonate({ firebaseId }) {
    const {
      data: {
        createImpersonation: { token },
      },
    } = await impersonate({
      variables: { data: { id: firebaseId } },
    });
    window.open(
      `${config.beta}/impersonate/${token}?clinicId=${data.id}`,
      "_blank"
    );
  }

  return (
    <span
      onClick={() => {
        quickImpersonate(data.roles[0]?.users[0].user);
      }}
    >
      <FaUserMd
        color="rgb(40, 140, 165)"
        size={20}
        style={{ marginLeft: 8, cursor: "pointer" }}
        title={
          "Impersonate First Admin User (" +
          data.roles[0]?.users[0]?.user?.firstName +
          " " +
          data.roles[0]?.users[0]?.user?.lastName +
          ")"
        }
      />
    </span>
  );
}

const syncTooLate = ({ lastSyncedAt, capability }, type) => {
  const nowDate = new Date();
  const dayDifference = nowDate?.getTime() - new Date(lastSyncedAt).getTime();
  const thirtyMinuteSync = Math.ceil(dayDifference / (1000 * 60 * 30)) > 1;
  const twentyFourHourSync = Math.ceil(dayDifference / (1000 * 3600 * 24)) > 1;
  const twelveHourSync = Math.ceil(dayDifference / (1000 * 3600 * 12)) > 1;

  const tenMinuteCapability = [
    "Appointment",
    "ClinicPet",
    "ClinicPetParent",
    "ClinicPetParentContactInfo",
    "ClinicPetToClinicPetParent",
  ];
  const twelveHourCapability = [
    "Service",
    "ServiceReminder",
    "Vaccination",
    "Prescription",
  ];
  const twentyfourHourCapability = [
    "AppointmentType",
    "ClinicEmployee",
    "ClinicRoom",
    "ClinicPetAlert",
    "ClinicPetParentAddress",
    "ServiceCategory",
    "Breed",
    "Species",
    "Color",
    "Sex",
  ];

  if (tenMinuteCapability.includes(capability)) {
    return thirtyMinuteSync;
  }

  if (twelveHourCapability.includes(capability)) {
    return twelveHourSync;
  }

  if (twentyfourHourCapability.includes(capability)) {
    return twentyFourHourSync;
  }

  return false;
};

const Capability = ({ capability, integration }) => {
  let lateSync = syncTooLate(capability, integration.type);

  return (
    <p
      style={{
        display: "flex",
        borderBottom: "1px dashed rgba(45,45,45,.5)",
        padding: "5px",
        paddingBottom: "20px",
        color: lateSync ? "red" : "inherit",
      }}
    >
      {capability.capability}
      <br></br>
      <br />
      {formatRelative(new Date(capability.lastSyncedAt), new Date())}
    </p>
  );
};

const searchCapabilitiesForSyncIssues = (capabilities, type) => {
  for (var i = 0; i < capabilities.length; i++) {
    const capability = capabilities[i];
    if (syncTooLate(capability, type)) {
      return true;
    }
  }
  return false;
};

const CapabilitiesButton = ({ integration, featureFlags }) => {
  const [capabilitiesVisibility, setCapabilitiesVisibility] = useState(false);
  const [isUnsynced, setIsUnsynced] = useState(false);

  if (!integration?.capabilities || integration?.capabilities.length <= 0) {
    return "";
  }

  const syncIssues = searchCapabilitiesForSyncIssues(
    integration.capabilities,
    integration.type
  );

  if (syncIssues && !isUnsynced) {
    setIsUnsynced(true);
  }

  return (
    <div style={{ position: "relative" }}>
      <Button
        style={{
          width: "235px",
          fontWeight: isUnsynced ? "bolder" : "normal",
          border: isUnsynced ? "none" : "",
          background: isUnsynced ? "rgb(240, 60, 60)" : "",
        }}
        onClick={() => {
          setCapabilitiesVisibility(!capabilitiesVisibility);
        }}
      >
        Capabilities
      </Button>
      <div
        // onClick = {() => setCapabilitiesVisibility(!capabilitiesVisibility)}
        style={{
          // position: "absolute",
          background: "white",
          right: "100%",
          padding: "15px",
          border: "#46475a solid 1px",
          borderTop: "#46475a solid 4px",
          display: capabilitiesVisibility ? "block" : "none",
        }}
      >
        {integration.capabilities.map((capability) => {
          return (
            <Capability integration={integration} capability={capability} />
          );
        })}
        <FaArrowAltCircleUp
          title="Close"
          style={{
            color: "#46475a",
            width: "100%",
            height: "2em",
            cursor: "pointer",
          }}
          onClick={() => {
            setCapabilitiesVisibility(!capabilitiesVisibility);
          }}
        />
      </div>
    </div>
  );
};

const IntegrationInfoButton = ({ integration }) => {
  // console.log(integration);
  const [copied, setCopied] = useState(false);

  const { type, ezyvet, vetdata, neo, bitwerx, syncvet } = integration;
  let credentials;
  let title;

  if (!type) {
    return null;
  }

  if (!ezyvet && !vetdata && !neo && !bitwerx && !syncvet) {
    return null;
  }

  try {
    switch (type.toLowerCase()) {
      case "ezyvet":
        credentials = JSON.stringify(
          { client_id: ezyvet.client_id, client_secret: ezyvet.client_secret },
          null,
          2
        );
        title = "Copy EzyVet Credentials";
        break;
      case "vetdata":
        credentials = vetdata.installationId;
        title = "Copy VetData Installation Id";
        break;
      case "neo":
        credentials = neo.authToken;
        title = "Copy Neo Auth Token / Api Key";
        break;
      case "bitwerx":
        credentials = bitwerx?.practiceId;
        title = "Copy Bitwerx Practice Id";
        break;
      case "syncvet":
        credentials = syncvet?.syncvetId;
        title = "Copy Syncvet Id";
        break;
      default:
        return null;
    }
  } catch (error) {
    // console.log(error);
    return null;
  }

  if (!credentials || credentials.length <= 0) {
    return null;
  }

  return (
    <div
      style={{ position: "relative", display: "inline-block", padding: "5px" }}
    >
      <p
        style={
          copied
            ? {
                display: "block",
                position: "absolute",
                background: "white",
                padding: "5px",
                bottom: "50%",
                left: "50%",
                boxShadow: "2px 2px 3px black",
                borderRadius: "5px",
                borderBottomLeftRadius: "0px",
              }
            : { display: "none" }
        }
      >
        Copied!
      </p>
      <FaClipboard
        style={{ cursor: "pointer" }}
        title={title || "Copy"}
        onClick={() => {
          setCopied(true);
          navigator.clipboard.writeText(credentials);
          setTimeout(() => {
            setCopied(false);
          }, 1500);
        }}
      />
    </div>
  );
};

export default function Clinics() {
  const [, setClinicName] = useGlobal("clinicName");
  const [, setClinicSettingId] = useGlobal("clinicSettingId");
  const [, setHasCustomizableFees] = useGlobal("hasCustomizableFees");
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useGlobal("modalState");
  const [, setSnackBars] = useGlobal("snackbars");
  const history = useHistory();
  const [cancel] = useMutation(Cancel_Subscription);
  const [updateStripeId] = useMutation(UPDATE_STRIPE_ID);
  const [createGenericFees] = useMutation(CREATE_FEES);

  const [createClinicSetting] = useMutation(CREATE_CLINIC_SETTING, {
    errorPolicy: "all",
  });

  async function confirmCancel({ id, cancellationReason, cancellationNote }) {
    setLoading(true);
    await cancel({
      variables: {
        where: { id },
        data: { cancellationReason, cancellationNote },
      },
    });
    setLoading(false);
    setSnackBars([{ message: "Success" }]);
  }

  async function updateStripeCustomerId({ id, stripeCustomerId }) {
    setLoading(true);
    try {
      await updateStripeId({
        variables: {
          where: { id },
          data: { stripeCustomerId },
        },
      });
      setLoading(false);
      setSnackBars([{ message: "Success" }]);
    } catch (error) {
      console.log(error);
    }
  }

  function showUpdateStripeId({ id, name, stripeCustomerId }) {
    return () => {
      setModalState({
        ...modalState,
        modalHeader: "Update Stripe Customer ID",
        modalContent: (
          <>
            <p>
              <b>Clinic:</b> {name}
            </p>
            <p>
              <b>Current Stripe ID:</b>{" "}
              {stripeCustomerId ? stripeCustomerId : "No stripe ID"}
            </p>
            <Form
              fields={[
                [
                  {
                    display: "New ID",
                    property: "stripeCustomerId",
                    type: "input",
                  },
                ],
              ]}
              notCrud={true}
              defaultState={{ id }}
              onCreate={updateStripeCustomerId}
              onCancel={() =>
                setModalState({
                  ...modalState,
                  showModal: false,
                })
              }
              onChange={console.log}
            />
          </>
        ),
        showModal: true,
        hideButtons: true,
        modalConfirm: () => {
          updateStripeCustomerId(id);
        },
      });
    };
  }
  function showCancelConfirm({ id }) {
    return () => {
      setModalState({
        ...modalState,
        modalHeader: "Cancel Clinic Subscription",
        modalContent: (
          <Form
            fields={[
              [
                {
                  display: "Reason",
                  property: "cancellationReason",
                  type: "select",
                  options: [
                    "Duplicate/Erroneous subscription",
                    "Never really used",
                    "Not using often enough",
                    "Price too high vs value",
                    "Integration for my PIMS not available",
                    "Missing key features",
                    "Support/Service issues",
                    "Privacy concerns",
                    "Outgrew solution",
                  ].map((value) => ({ key: value, display: value })),
                  required: true,
                },
              ],
              [
                {
                  display: "Note",
                  type: "textarea",
                  property: "cancellationNote",
                  required: true,
                },
              ],
            ]}
            defaultState={{ id, cancellationReason: "" }}
            title=" "
            notCrud={true}
            onCreate={confirmCancel}
            onCancel={() =>
              setModalState({
                ...modalState,
                showModal: false,
              })
            }
            onChange={console.log}
          />
        ),
        showModal: true,
        hideButtons: true,
        modalConfirm: () => {
          confirmCancel(id);
        },
      });
    };
  }

  const createOneClinicSetting = async (clinicId) => {
    // console.log("CLINIC SETTING NOT FOUND");
    const newClinicSetting = await createClinicSetting({
      variables: {
        data: {
          clinic: {
            connect: {
              id: clinicId,
            },
          },
        },
      },
    }).catch((err) => console.log(err));

    createDefaultPFC(clinicId, newClinicSetting.data.createClinicSetting.id);
  };

  const findDataIntegration = (integrations) => {
    const result = integrations.find(({ isActive, type }) => {
      return type !== "Bitwerx" && type !== "PPC" && isActive;
    });

    return result;
  };

  const isUnsynced = (integration, flags) => {
    try {
      if (!integration || integration === undefined) {
        return false;
      }
      // console.log(integration.id + " PASSED")
      const { capabilities } = integration;

      for (let i = 0; i < capabilities.length; i++) {
        const capability = capabilities[i];

        if (syncTooLate(capability)) {
          return true;
        }
      }
      return false;
    } catch (error) {
      // console.log("ISUNSYNCED ERROR: " + error + " - " + integration.id);
      return false;
    }
  };

  const oneDigit = (num) => {
    if (num > 9) {
      return num;
    }
    return "0" + num;
  };

  const generateNowDateString = () => {
    const nowDate = new Date();
    const y = nowDate.getFullYear();
    const m = oneDigit(nowDate.getUTCMonth() + 1); // Without the +1, the month is 1 step behind
    const d = oneDigit(nowDate.getUTCDate());
    const h = oneDigit(
      nowDate.getUTCHours() > 3
        ? nowDate.getUTCHours() - 3
        : nowDate.getUTCHours()
    ); // Looks backward three hours
    const min = oneDigit(nowDate.getUTCMinutes());
    const sec = oneDigit(nowDate.getUTCSeconds());
    const mil = oneDigit(nowDate.getUTCMilliseconds());

    return `${y}-${m}-${d}T${h}:${min}:${sec}.${mil}Z`;
  };

  const createDefaultPFC = async (clinicId, clinicSettingId) => {
    console.log("NO PAYMENT FEE CONFIGURATION FOUND, GENERATING NEW");
    const newFees = await createGenericFees({
      variables: {
        data: {
          onlineProcessingFeePercent: 0.03,
          onlineClientServiceFeePercent: 0.03,
          terminalProcessingFeePercent: 0.03,
          terminalClientServiceFeePercent: 0.03,
          onlineProcessingFeeFlat: 0,
          terminalProcessingFeeFlat: 0,
          clinicSetting: {
            connect: {
              id: clinicSettingId,
            },
          },
        },
      },
    });

    history.push(
      `/paymentFeeConfigs/${newFees.data.createPaymentFeeConfiguration.id}`
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <PrismaServerFilterTable
        title="Clinics"
        queryName="findManyClinic"
        useQuery={useClinicsQuery}
        renderedRows={100}
        prismaVersion={2}
        queryVariables={{ orderBy: { name: "asc" } }}
        filterConfigs={[
          { property: "name", display: "Name" },
          { property: "id", display: "Clinic ID" },
          { property: "crmId", display: "Clinic CRM ID" },
          { property: "organization.name", display: "Organization" },
          { property: "city", display: "City" },
          { property: "state", display: "State" },
          { property: "firebaseId", display: "Firebase Id" },
          {
            property: "clinicEmail",
            display: "Clinic Connect Enabled",
            type: "checkbox",
            handlePropertyFilter: ({ filters, filterEdit }) => {
              if (filters.clinicEmail) filterEdit["clinicEmail_not"] = null;
            },
          },
          {
            property: "unsynced",
            display: "Late Sync",
            type: "checkbox",
            handlePropertyFilter: ({ filters, filterEdit }) => {
              filterEdit["integrations"] = {
                some: {
                  capabilities: {
                    some: {
                      lastSyncedAt: {
                        lte: generateNowDateString(),
                      },
                    },
                  },
                  isActive: { equals: true },
                },
              };
            },
          },
        ]}
        columns={[
          {
            Header: "",
            hidefilter: true,
            blockSort: true,
            width: 200,
            accessor: ({ data }) => {
              return (
                <Row>
                  <CA>
                    <FaEdit
                      color="#2ABA66"
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Edit Clinic"
                      onClick={() => {
                        history.push(`/clinics/${data.id}`);
                      }}
                    />

                    {/* <FaCode
                      color="#2ABA66"
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="View"
                      onClick={() => {
                        setJson({ type: "clinics", data });
                        history.push("/json/view");
                      }}
                    /> */}
                    <FaLock
                      color="#46475a"
                      size={20}
                      title="Manage Roles"
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      onClick={() => {
                        setClinicName(data.name);
                        history.push(`/clinics/manage/roles/${data.id}`);
                      }}
                    />
                    {data.isClinicActive ? (
                      <FaBan
                        color="#E9573F"
                        size={20}
                        style={{ marginLeft: 8, cursor: "pointer" }}
                        title="Cancel Subscription"
                        onClick={showCancelConfirm(data)}
                      />
                    ) : null}
                    <FaDollarSign
                      color={
                        data.clinicSetting &&
                        data.clinicSetting.paymentFeeConfig
                          ? "#2ABA66"
                          : data.clinicSetting
                          ? "#FFDB19"
                          : "#E9573F"
                      }
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Change Payment Fee Configuration"
                      onClick={() => {
                        setHasCustomizableFees(
                          data.clinicSetting.hasCustomizableFees
                        );
                        setClinicSettingId(data.clinicSetting.id);
                        setClinicName(data.name);
                        if (data.clinicSetting?.paymentFeeConfig) {
                          history.push(
                            `/paymentFeeConfigs/${data.clinicSetting.paymentFeeConfig.id}`
                          );
                        } else if (data.clinicSetting) {
                          createDefaultPFC(data.id, data.clinicSetting.id);
                        } else {
                          createOneClinicSetting(data.id);
                        }
                      }}
                    />
                    <FaStripeS
                      style={{
                        cursor: "pointer",
                        marginBottom: 1,
                        marginLeft: 6,
                      }}
                      onClick={showUpdateStripeId(data)}
                    />
                    {data.roles[0]?.users[0]?.user ? (
                      <ImpersonationLink data={data} />
                    ) : (
                      ""
                    )}
                    {data.featureFlags?.length > 0 ? (
                      <FaFlag
                        color="#2ABA66"
                        size={20}
                        style={{ marginLeft: 8, cursor: "pointer" }}
                        title="Feature Flags"
                        onClick={() => {
                          setClinicName(data.name);
                          history.push(`/feature-flags/clinic/${data.id}`);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <FaCreditCard
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      onClick={() => {
                        setClinicName(data.name);
                        history.push(`/terminals/${data.id}`);
                      }}
                    />
                  </CA>
                </Row>
              );
            },
          },
          {
            Header: "Name",
            accessor: ({ data: { name, isClinicActive } }) => {
              if (!isClinicActive) {
                return (
                  <p style={{ opacity: "0.5", fontStyle: "italic" }}>
                    {name} - Inactive
                  </p>
                );
              }
              return name;
            },
          },
          {
            Header: "Clinic ID | CRM ID",
            accessor: ({ data: { id, crmId } }) => {
              return (
                <div>
                  <p>{id}</p>
                  {crmId ? (
                    <span>
                      <hr></hr>
                      <p>
                        <strong>crmId:</strong> {crmId}
                      </p>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          // {Header: "Clinic Email", accessor: "clinicEmail"},
          {
            Header: "SMS Number",
            accessor: ({
              data: { clinicSetting, isClinicActive, clinicPhoneNumber },
            }) => {
              const textingNumber = clinicPhoneNumber?.filter(
                (el) => el.capability === "SMS" && el.isPrimary && el.isActive
              );
              const verifiedCampaign =
                clinicSetting?.campaignRegistryCampaigns.length;
              if (isClinicActive) {
                return textingNumber.map((number) => {
                  if (verifiedCampaign) {
                    return (
                      <p style={{ textAlign: "center" }}>{number.number}</p>
                    );
                  }

                  return (
                    <Popup
                      styles={{ textAlign: "center", color: "red" }}
                      content={"Campaign is Unverified"}
                    >
                      <p style={{ textAlign: "center", color: "red" }}>
                        {number.number}
                      </p>
                    </Popup>
                  );
                });
              }
              return "";
            },
          },
          { Header: "City", accessor: "city" },
          { Header: "State", accessor: "state" },
          { Header: "Address", accessor: "fullAddress" },
          {
            Header: "PIMS",
            accessor: ({ data: { integrations } }) => {
              if (integrations.length) {
                return integrations.map((integration) => {
                  let result = integration.type;
                  if (
                    (integration.type === "Vetdata" && integration.vetdata) ||
                    (integration.type === "SyncVet" && integration.syncvet)
                  ) {
                    result += ` - ${
                      integration.type === "Vetdata"
                        ? integration.vetdata.system
                        : integration.syncvet.pimsName
                    }`;
                    return (
                      <p
                        style={{
                          color: !integration.isActive
                            ? { opacity: "0.5", fontStyle: "italic" }
                            : isUnsynced(findDataIntegration(integrations))
                            ? "red"
                            : "inherit",
                        }}
                      >
                        {result}
                        <IntegrationInfoButton integration={integration} />
                      </p>
                    );
                  }
                  return (
                    <p
                      style={
                        integration.isActive === false
                          ? { opacity: "0.5", fontStyle: "italic" }
                          : {}
                      }
                    >
                      {result}
                      {<IntegrationInfoButton integration={integration} /> ? (
                        <IntegrationInfoButton integration={integration} />
                      ) : null}
                    </p>
                  );
                });

                // return integrations[0].type;
              }
              return "";
            },
          },
          {
            Header: "Organization",
            accessor: ({ data: { organization } }) =>
              organization ? organization.name : "",
          },
          {
            Header: "Vets",
            Cell: ({ row }) => (
              <Button
                onClick={() => {
                  setClinicName(row.name);
                  history.push(`/clinics/${row.id}/vet`);
                }}
              >
                View
              </Button>
            ),
            accessor: ({ data }) => {},
          },
          {
            Header: "Last Synced At",
            accessor: ({ data: { integrations, featureFlags } }) => {
              const primaryIntegration = integrations.length
                ? findDataIntegration(integrations)
                : null;

              return (
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      color:
                        primaryIntegration && isUnsynced(primaryIntegration)
                          ? "red"
                          : "inherit",
                    }}
                  >
                    {primaryIntegration
                      ? formatRelative(
                          new Date(primaryIntegration.lastSyncedAt),
                          new Date()
                        )
                      : ""}
                  </p>

                  {primaryIntegration ? (
                    <CapabilitiesButton
                      integration={primaryIntegration}
                      featureFlags={featureFlags}
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
        ]}
        // urlQueries={clinicQueries}
      />
    </div>
  );
}
