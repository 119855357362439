import ApolloClient from "apollo-boost";
import { getStoredState } from "../components/app/persistGate";
import firebase from "./firebase";
import { env as appEnv } from "./env";
const env = {
  demo: "https://core-api.demo.gettelevet.com/",
  // default to QA1 if we can't find a QA lane
  development: process.env.QA
    ? `https://core-api.qa${process.env.QA}.dev.otto.vet/`
    : "https://qa1.core-api.gettelevet.com",
  production: "https://core-api.production.otto.vet",
  yak: "https://core-api.yak.televet.com",
  // local: "https://core-api.qa162.dev.otto.vet/",
  local: "https://core-api.test.dev.otto.vet/",
};
const client = new ApolloClient({
  uri:
    appEnv.REACT_APP_CORE_API_URI ||
    env[process.env.REACT_APP_ENV] ||
    env.local,
  request: async (operation) => {
    const fbToken = firebase.auth().currentUser
      ? await firebase.auth().currentUser.getIdToken()
      : null;
    const { token } = await getStoredState();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${fbToken || token}` : "",
        clinic: sessionStorage.getItem("clinic")
          ? sessionStorage.getItem("clinic")
          : "",
      },
    });
  },
});

export default client;
